<button class="wrapper" (click)="openWallet()" *ngIf="!loading">
    <div>
        <mat-icon svgIcon="wallet" />
    </div>
    <p *ngIf="wallet.credits && wallet.credits > 0">
        {{ wallet.credits.toFixed(2) }} Credits
    </p>
    <hr *ngIf="wallet && wallet.credits > 0 && wallet['vs credits'] > 0" />
    <p *ngIf="wallet && wallet['vs credits'] && wallet['vs credits'] > 0">
        {{ wallet["vs credits"] }} VS Credits
    </p>
</button>

<ng-template #walletDialog>
    <div class="wallet" [ngClass]="
            loadingStripe
                ? 'loading-stripe ' + breakpointService.currentBreakpointClass
                : breakpointService.currentBreakpointClass
        ">
        <button class="close" (click)="closeWallet()">
            <mat-icon svgIcon="xMark2" />
        </button>
        <div class="section">
            <div class="balance">
                <mat-icon svgIcon="balance" />
                <div class="text">
                    <p>Credit balance</p>
                    <h1>{{ wallet.credits.toFixed(2) }}</h1>
                    <h3>VS Credits: {{ wallet["vs credits"] }}</h3>
                </div>
            </div>
            <div class="add-funds" *ngIf="
                    showAddFunds()
                ">
                <p>Add funds to your wallet</p>
                <div class="predefined-values">
                    <div class="value" *ngFor="let value of [100, 250, 500]">
                        <input hidden type="radio" [(ngModel)]="selectedPredefinedAmount"
                            (ngModelChange)="amount = value" [value]="value" name="predefined" [id]="value" />
                        <label [for]="value">{{ value }} Credits</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="custom-amount" *ngIf="
                    showAddFunds()
                ">
                <h5>Or purchase a custom amount</h5>
                <div class="order">
                    <input type="number" [(ngModel)]="amount" (ngModelChange)="selectedPredefinedAmount = null"
                        id="amount" autocomplete="off" />
                    <button (click)="addFundsToWallet()" [disabled]="amount <= 0">
                        Order
                    </button>
                </div>
            </div>
            <div class="transactions">
                <h5>List of transactions</h5>
                <div class="list">
                    <div class="transaction" *ngFor="let transaction of transactions">
                        <div class="date">
                            <h3>
                                {{
                                getShortDate(transaction.created).split(
                                " "
                                )[0]
                                }}
                            </h3>
                            <h6>
                                {{
                                getShortDate(transaction.created).split(
                                " "
                                )[1]
                                }}
                            </h6>
                        </div>
                        <div class="description">
                            <p>{{ prettyFormat(transaction.description) }}</p>
                        </div>
                        <div class="amount" [ngClass]="
                                transaction.type === 'Wallet'
                                    ? transaction.transactionType
                                    : ''
                            ">
                            <p>
                                {{ negative(transaction)
                                }}{{ getAmount(transaction) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>